export default {
    //url:'http://test.tsied.com/api/v1',
    //'http://202.120.35.193/api/v1',
    url: process.env.VUE_APP_API_ROOT?process.env.VUE_APP_API_ROOT:window.location.origin+'/tmbs/api/v1',
    modules: {
        User: {
            SignIn: '/user/login',
            UploadImage:'/user/upload-image',
            UploadAttachment:'/user/upload-attachment',
        },
    }
}
