export default {
    namespaced: true,
    state: {
    },
    mutations: {
        updateCache(state, payload) {
            for(let x in payload) {
                state[x] = Object.assign({},payload[x]);
            }
        },
    },
    actions: {},
    getters: {}
}
