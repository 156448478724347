export default {
    namespaced: true,
    state: {
        loading: false,
        processing: false,
        cache: {},
        collapsed: null,
        semester: '0',
        page_size: -1,

    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload;
        },
        start(state) {
            state.loading = true
        },
        stop(state) {
            state.loading = false
        },
        setCollapsed(state, value) {
            state.collapsed = value;
            localStorage.setItem('collapsed', state.collapsed);
        },
        setSemester(state, value) {
            state.semester = value;
            localStorage.setItem('semester', state.semester + '');
            //localStorage.setItem('semester', '');
        },
        setPageSize(state, value) {
            state.page_size = value;
            localStorage.setItem('page_size', state.page_size.toString());
        }
    },
    actions: {},
    getters: {
        isCollapsed(state) {
            if (state.collapsed === null) {
                let col = localStorage.getItem('collapsed');
                if (col) {
                    state.collapsed = col === "true"
                } else {
                    state.collapsed = window.innerWidth < 1200;
                    localStorage.setItem('collapsed', state.collapsed);
                }
            }
            return state.collapsed;
        },
        which_semester(state) {
            if ('0' === state.semester) {
                const semester = parseInt(localStorage.getItem('semester'));
                state.semester = (isNaN(semester) || !semester) ? "0" : (semester + '');
            }
            return state.semester;
        },
        pageSize(state) {
            if (state.page_size === -1) {
                let size = localStorage.getItem('page_size');
                if (size) {
                    state.page_size = parseInt(size) || 10;
                } else {
                    state.page_size = 10;
                }
                if (state.page_size !== parseInt(size))
                    localStorage.setItem('page_size', state.page_size.toString());
            }
            return state.page_size;
        }
    }
}
