import VueCookie from 'vuecookie'

function readUserInfo(state) {
    let saved = VueCookie.get('tmbs.user_info');
    if (saved) {
        try {
            const payload = JSON.parse(saved);
            if (payload) {
                state.name = payload.name;
                state.token = payload.token;
                state.email = payload.email;
                state.role_level = payload.role_level;
                state.user_id = payload.user_id;
                state.department = payload.department;
                state.mobile = payload.mobile;
                state.time = payload.time;
                state.disabled = parseInt(payload.disabled);
                state.ulogo = payload.ulogo;
                state.logo = payload.logo;
                state.position = payload.position;

            }
        } catch (e) {
            console.error(e);
        }
    }
}

export default {
    namespaced: true,
    state: {
        name:'',
        token:'',
        email:'',
        role_level:0,
        user_id:'',
        mobile:'',
        time:0,
        disabled:0,
        logo:null,
        ulogo:null,
        position:null,

    },
    mutations: {
        setUserInfo(state,payload) {
            state.name = payload.name;
            if (payload.token) state.token = payload.token;
            state.email = payload.email;
            state.role_level = payload.role_level;
            state.user_id = payload.user_id;
            state.department = payload.department;
            state.mobile = payload.mobile;
            state.disabled = parseInt(payload.disabled);
            state.ulogo = payload.ulogo;
            state.logo = payload.logo;
            state.position = payload.position;

            payload.token = state.token;
            payload.time = new Date().getTime();

            VueCookie.set('tmbs.user_info',JSON.stringify(payload),{expires:'2D'});
            },

        setUserTime(state) {
            state.time = new Date().getTime();
            VueCookie.set('tmbs.user_info',JSON.stringify(state),{expires:'2D'});
        },

        readUserInfo(state) {
          readUserInfo(state);
        },
        clear(state) {
            state.name = '';
            state.id = '';
            state.token = '';
            state.email = '';
            state.role_level = 0;
            state.user_id = '';
            state.department = '';
            state.mobile = '';
            state.time = 0;
            state.disabled = 0;
            state.ulogo = null;
            state.logo = null;
            state.position = null;
            VueCookie.delete('tmbs.user_info');
        },
    },
    actions: {

    },
    getters: {
        user(state){
            if (!(state.token && state.name && state.id)) {
                readUserInfo(state);
            }
            return state;
        }
    }
}
