import './element'
import Vue from 'vue'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css'
import qs from "qs";
import config from "../config/"
import utility from "./utility"
import '../common/directives/picture-viewer'
import '../common/directives/mobile-dialog'

Vue.use(utility);
Vue.prototype.$qs = qs;

/**
 * My Tool
 */

Vue.prototype.$apiUrl = function(command) {
    const cmd = command.split('?');

    const command_array = cmd[0].split('.');

    let value = config.api.modules;
    for (let i = 0; i < command_array.length; i++) {
        value = value[command_array[i]];
        if (value === undefined) {
            if ( i === command_array.length - 1) {
                value = '';
                for(let j=0; j<=i; j++) {
                    value += '/' + command_array[j].addHyphen();
                }
                break;
            }
            break;
        }
    }

    if (value === undefined) { // 没找到定义的api，那么我自己为此生成一个
        value = "";
        for (let i = 0; i < command_array.length; i++) {
            value += '/' + command_array[i].addHyphen();
        }
        return config.api.url + value;
    }
    else  return config.api.url + value;

}

Vue.prototype.$api = function (command, data, warning, quiet) {
    const that = this;
    return new Promise(function (resolve, reject) {
        // check if command is right

        const cmd = command.split('?');
        const params = cmd.length === 2?'?'+cmd[1]:'';
        const command_array = cmd[0].split('.');

        let value = config.api.modules;
        for (let i = 0; i < command_array.length; i++) {
            value = value[command_array[i]];
            if (typeof value === 'undefined') {
                if ( i === command_array.length - 1) {
                    value = '';
                    for(let j=0; j<=i; j++) {
                        value += '/' + command_array[j].addHyphen();
                    }
                    break;
                }
                break;
            }
        }

        if (typeof value === 'undefined') {
            value = "";
            for (let i = 0; i < command_array.length; i++) {
                value += '/' + command_array[i].addHyphen();
            }
        }

        const post_data = Object.assign({user_token: that.$store.state.user.token}, data);

        if (!quiet) that.$store.commit('process/start');
        that.$store.commit('user/setUserTime');

        axios.post(config.api.url + value + params , qs.stringify(post_data),
            {headers: {'Accept': 'application/json'}})
            .then(function (response) {
                that.$store.commit('process/stop');
                if ( warning && !response.data.success) {
                    that.$notify({
                            title: '错误',
                            message: response.data.text,
                            type: 'error'
                    });
                    reject({response:response,state:499});
                } else {
                    resolve(response)
                }
            })
            .catch(function (error) {
                that.$store.commit('process/stop');
                if (error && error.response && error.response.data) {
                    if (error.response.status === 401) {
                        that.$alert('您由于长时间未响应，系统自动登出', '提示', {
                            confirmButtonText: '确定',
                            callback: () => {
                                that.$store.commit('user/clear');
                                that.$router.replace('/sign-in');
                            }
                        });
                    } else {
                        let text = '';
                        if (error.response) {
                            console.error('err-response:',error.response);
                            if (error.response.statusText) {
                                text = error.response.statusText;
                            }
                            if (error.response.data) {
                                if ( error.response.data.message )  text = error.response.data.message;
                                if (error.response.data.text) {
                                    text = error.response.data.text;
                                }
                            }
                            if (error.response.data && error.response.data.original) {
                                text= error.response.data.original.text;
                            }
                        } else {
                            console.error('err:',error);
                        }
                        that.$notify({
                            title: '错误',
                            message: text,
                            type: 'error'
                        });
                    }
                } else {
                    console.error(error,'unknow error:');
                    that.$alert('&nbsp;&nbsp;网络故障，请稍后再试!', '提示', {
                        confirmButtonText: '确定',
                        dangerouslyUseHTMLString: true,
                        type: 'error'
                    });
                }

                reject(error);
            });
    })

};

Vue.prototype.$messageBox = {
    error: function (message, title,callback) {
        if (!title) title = '错误';
        Vue.prototype.$alert(message, title, {
            confirmButtonText: '确定',
            dangerouslyUseHTMLString:true,
            type:'error',
            callback:callback
        });
    },
    success: function (message, title,callback) {

        if (!title) title = '提示';
        Vue.prototype.$alert(message, title, {
            confirmButtonText: '确定',
            dangerouslyUseHTMLString: true,
            type: 'success',
            callback:callback
        });
    }
};


Vue.prototype.$notice = {
    error: function (message, title) {
        if (!title) title='错误';
        Vue.prototype.$notify({
            title: title,
            message: message,
            type:'error'
        });
    },
    success: function (message, title) {
        if (!title) title='提示';
        Vue.prototype.$notify({
            title: title,
            message: message,
            type:'success'
        });
    },
};

Vue.prototype.$affirm = function (text) {
    return Vue.prototype.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
        center: false,
        lockScroll:true,

    });
};
