import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import process from './process'
import preset from './preset'
import cache from './cache'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,process,preset,cache
  }
})
