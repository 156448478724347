import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/'
import store from './store'
import config from './config/'

// Router Hook
router.beforeEach((to, from, next) => {
    let processed = false;
    let user = store.getters['user/user'];

    if (['signin'].indexOf(to.name) === -1) {
        const time = new Date().getTime();
        if (!user.token || (time - user.time) / 1000 >= 3600) {
            next({name: 'signin'});
            processed = true;
        }
    }

    const role_level = parseInt(user.role_level);
    let can = false;
    let empty = true;
    for (let i = 0; i < to.matched.length; i++) {
        const route = to.matched[i];
        if (route.meta && route.meta.level) {
            empty = false;
            if (route.meta.level <= role_level) {
                can = true;
            } else if (route.meta.position) {
                    can = (user.position == route.meta.position);
            }
        }
    }

    if (!empty && !can) {
        next({path: '/'});
        processed = true;
    }

    if (['signin', '我的档案'].indexOf(to.name) === -1) {
        const user = store.state.user;
        if (!user.name || user.name === '' || user.disabled) {
            next({path: '/profile'});
            processed = true;
        }
    }
    if (!processed) next();
});

router.afterEach(() => {
});

Vue.config.productionTip = false;
Vue.prototype.$organization = config.org;

Vue.filter('human_size', function (value) {
    let size = parseInt(value);
    if (size < 1024) {
        return size+" 字节";
    }
    size = Math.round(size/10.24)/100;
    if (size < 1024) {
        return size+" KB";
    }

    size = Math.round(size/10.24)/100;
    if (size < 1024) {
        return size+" MB";
    }

    size = Math.round(size/10.24)/100;
    return size+" GB";

})

//const app =
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
//load preset
/*
app.$api('User.Preset',{},1).then(res=>{
    store.commit('preset/set',res.data.data);
});
*/
store.dispatch('preset/loadPreset');

