import mobile from "../common/mobile-input"

export default [
    {
        path: '/',
        name: '',
        icon: 'el-icon-tickets',
        component: () => import(/* webpackChunkName: "home" */ '../views/' + mobile() + 'home.vue'),
        hidden: true,
        children: [
            {path: "", redirect: '/dashboard'},
            {
                path: "/admin/import-syllabus",
                component: () => import(/* webpackChunkName: "import" */ '../views/' + 'import-syllabus'),
                name: '导入课程表',
                meta: {level: 3, model: 'Syllabus'}
            },
            {
                path: "/admin/import-teacher",
                component: () => import(/* webpackChunkName: "import" */ '../views/' + 'import-teacher'),
                name: '导入教师',
                meta: {level: 3, model: 'Teacher'}
            },
            {
                path: "/admin/import-visitor",
                component: () => import(/* webpackChunkName: "import" */ '../views/' + 'import-visitor'),
                name: '导入用户',
                meta: {level: 3, model: 'Visitor'}
            },
            {
                path: '/admin/lesson/edit/:id',
                name: '编辑课程',
                icon: 'el-icon-edit',
                component: () => import(/* webpackChunkName: "import" */ '../views/' + 'editor'),
            },
            {
                path: '/admin/activate/edit/:id',
                name: '编辑活动',
                icon: 'el-icon-edit',
                component: () => import(/* webpackChunkName: "import" */ '../views/' + 'activate'),
            },
        ]
    },
    {
        path: '/host',
        icon: 'el-icon-tickets',
        hidden: true,
        component: () => import(/* webpackChunkName: "import" */ '../views/' + 'host'),
        name: '课后报告直查',
        meta: {level: 1, model: 'statistics', hidden: true}
    },
    {
        path: '/',
        name: '',
        icon: 'el-icon-s-grid',
        meta: {mobile: true,},
        component: () => import(/* webpackChunkName: "home" */ '../views/' + mobile() + 'home.vue'),
        children: [
            {
                path: '/profile',
                component: () => import(/* webpackChunkName: "profile" */ '../views/' + mobile() + 'profile'),
                name: '我的档案'
            },
        ]
    },
    {
        path: '/',
        name: '',
        icon: 'el-icon-s-grid',
        component: () => import(/* webpackChunkName: "home" */ '../views/' + mobile() + 'home.vue'),
        children: [
            {
                path: '/dashboard',
                component: () => import(/* webpackChunkName: "home" */ '../views/' + mobile() + 'dashboard'),
                name: '课程表'
            }
        ]
    },
    {
        path: '/',
        name: '',
        icon: 'el-icon-date',
        component: () => import(/* webpackChunkName: "home" */ '../views/' + mobile() + 'home.vue'),
        children: [
            {
                path: '/calendar',
                component: () => import(/* webpackChunkName: "home" */ '../views/' + mobile() + 'calendar'),
                name: '课程日历'
            }
        ]
    },
    {
        path: '/',
        name: '',
        icon: 'el-icon-user',
        component: () => import(/* webpackChunkName: "home" */ '../views/' + mobile() + 'home.vue'),
        meta: {level: 2,wide: true},
        children: [
            {
                icon: 'el-icon-user',
                path: '/tm-teacher',
                component: () => import(/* webpackChunkName: "room" */ '../views/admin-teacher'),
                name: '教师管理',
                meta: {friendly: '教师', model: 'Teacher'}
            },
        ]
    },
    {
        path: '/',
        name: '',
        icon: 'el-icon-lollipop',
        meta: {level: 2,wide:true},
        component: () => import(/* webpackChunkName: "home" */ '../views/' + mobile() + 'home.vue'),
        children: [
            {
                icon: 'el-icon-collection',
                path: '/admin/lesson',
                component: () => import(/* webpackChunkName: "room" */ '../views/admin-lesson'),
                name: '课程管理',
                meta: {friendly: '课程', model: 'Course'}
            },
        ]
    },
    {
        path: '/',
        name: '',
        icon: 'el-icon-news',
        component: () => import(/* webpackChunkName: "home" */ '../views/' + mobile() + 'home.vue'),
        meta: {level: 3, wide: true},
        children: [
            {
                meta: {friendly: '活动', model: 'Activate'},
                path: '/activate',
                component: () => import(/* webpackChunkName: "test" */ '../views/admin-activate'),
                name: '活动管理'
            }
        ]
    },
    {
        path: '/privilege',
        name: '权限设置',
        icon: 'el-icon-setting',
        component: () => import(/* webpackChunkName: "home" */ '../views/home.vue'),
        meta: {level: 3, wide: true},
        children: [
            {
                icon: 'el-icon-user',
                path: '/visitor',
                component: () => import(/* webpackChunkName: "visitors" */ '../views/admin-visitor'),
                name: '用户管理',
                meta: {friendly: '用户', model: 'Visitor',wide: true}
            },
            {
                icon: 'el-icon-s-finance',
                path: '/academy',
                component: () => import(/* webpackChunkName: "visitors" */ '../views/admin-academy'),
                name: '单位管理',
                meta: {friendly: '单位', model: 'Academy',wide: true}
            },
            {
                icon: 'el-icon-position',
                path: '/area',
                component: () => import(/* webpackChunkName: "visitors" */ '../views/admin-area'),
                name: '地区管理',
                meta: {level: 4,friendly: '地区', model: 'Area',wide: true}
            },
            {
                icon: 'el-icon-lollipop',
                path: '/tm-institute',
                component: () => import(/* webpackChunkName: "room" */ '../views/admin-admin'),
                name: '助教管理',
                meta: {friendly: '助教管理', model: 'Institute',wide: true}
            },
            {
                icon: 'el-icon-lock',
                path: '/admin/lock',
                component: () => import(/* webpackChunkName: "system" */ '../views/admin-lock'),
                name: '会议控制',
                meta: {friendly: '会议', model: 'Lock', copy: false, level: 4}
            },
        ]
    },
    {
        path: '/production',
        name: '课程后台',
        icon: 'el-icon-school',
        component: () => import(/* webpackChunkName: "production" */ '../views/' + mobile() + 'home.vue'),
        meta: {level: 10, wide: true},
        children: [
            {
                icon: 'el-icon-help',
                path: '/admin/course',
                component: () => import(/* webpackChunkName: "adminadmin" */ '../views/admin-course'),
                name: '课程列表',
                meta: {friendly: '课程', model: 'Course'}
            },
            {
                icon: 'el-icon-s-order',
                path: '/admin/syllabus',
                component: () => import(/* webpackChunkName: "adminadmin" */ '../views/admin-plan'),
                name: '排课管理',
                meta: {friendly: '排课', model: 'Plan',hidden:true}
            },
            {
                icon: 'el-icon-s-grid',
                path: '/admin/schedule',
                component: () => import(/* webpackChunkName: "adminadmin" */ '../views/admin-schedule'),
                name: '时间表',
                meta: {friendly: '时间表', model: 'Schedule'}
            },
            {
                icon: 'el-icon-wind-power',
                path: '/admin/semester',
                component: () => import(/* webpackChunkName: "adminadmin" */ '../views/admin-semester'),
                name:'学期',
                meta:{friendly: '学期', model: 'Semester'},
            },
            {
                icon: 'el-icon-first-aid-kit',
                path: '/admin/room-group',
                component: () => import(/* webpackChunkName: "adminadmin" */ '../views/admin-admin'),
                name:'会议组',
                meta:{friendly: '会议组', model: 'RoomGroup',copy:true},
            }
        ]
    },
    {
        path: '/admin',
        name: '后台设置',
        icon: 'el-icon-setting',
        component: () => import(/* webpackChunkName: "home" */ '../views/' + mobile() + 'home.vue'),
        meta: {level: 10, wide: false},
        children: [
            {
                icon: 'el-icon-message-solid',
                path: '/admin/announcement',
                component: () => import(/* webpackChunkName: "adminadmin" */ '../views/' + mobile() + 'admin-admin'),
                name: '公告管理',
                meta: {wide: true, friendly: '公告', 'model': 'Announcement', copy: true}
            },
            {
                icon: 'el-icon-star-on',
                path: '/tm-user',
                component: () => import(/* webpackChunkName: "room" */ '../views/' + mobile() + 'admin-tm-user'),
                name: '腾讯用户',
                meta: {friendly: '用户', model: 'TMRoom'}
            },
            {
                icon: 'el-icon-sort',
                path: '/categories',
                component: () => import(/* webpackChunkName: "room" */ '../views/' + mobile() + 'admin-admin'),
                name: '课程分类',
                meta: {friendly: '分类', model: 'CourseCategory'}
            },
            {
                icon: 'el-icon-ice-cream-square',
                path: '/manual-upload',
                component: () => import(/* webpackChunkName: "room" */ '../views/' + mobile() + 'admin-admin'),
                name: '上传使用指南',
                meta: {friendly: '指南', model: 'Manual'}
            },
            {
                icon: 'el-icon-s-operation',
                path: '/parameters',
                component: () => import(/* webpackChunkName: "parameters" */ '../views/' + mobile() + 'admin-admin'),
                name: '参数管理',
                meta: {friendly: '参数', model: 'Parameter', copy: true}
            },
            {
                icon: 'el-icon-discount',
                path: '/admin-tm-room',
                component: () => import(/* webpackChunkName: "syslogs" */ '../views/' + mobile() + 'admin-tm-room'),
                name: '会议室',
                meta: {friendly: '日志', model: 'TencentRoom',edit:false}
            },
            {
                icon: 'el-icon-key',
                path: '/admin-syslog',
                component: () => import(/* webpackChunkName: "syslogs" */ '../views/' + mobile() + 'admin-syslog'),
                name: '系统日志',
                meta: {friendly: '日志', model: 'SysLog',}
            },
            {
                icon: 'el-icon-present',
                path: '/admin-scheduler',
                component: () => import(/* webpackChunkName: "syslogs" */ '../views/' + mobile() + 'admin-scheduler'),
                name: '调度日志',
                meta: {friendly: '日志', model: 'Scheduler',}
            },
        ]
    },
    {
        path: '/',
        name: '报表与监控',
        icon: 'el-icon-monitor',
        meta: {level: 1, wide: true},
        component: () => import(/* webpackChunkName: "home" */ '../views/' + mobile() + 'home.vue'),
        children: [
            {
                path: '/statistics',
                icon: 'el-icon-notebook-1',
                component: () => import(/* webpackChunkName: "home" */ '../views/' + mobile() + 'statistics'),
                name: '课后报告'
            },
            {
                icon: 'el-icon-camera',
                path: '/monitor',
                component: () => import(/* webpackChunkName: "monitor" */ '../views/admin-monitor'),
                name: '实时监控',
            },
            {
                icon: 'el-icon-document',
                path: '/reports',
                meta: {level: 3, wide: true},
                component: () => import(/* webpackChunkName: "monitor" */ '../views/admin-report'),
                name: '报表下载',
            },
            {
                icon: 'el-icon-s-marketing',
                path: '/charts',
                meta: {level: 3, wide: true},
                component: () => import(/* webpackChunkName: "monitor" */ '../views/admin-charts'),
                name: '统计图表',
            },
            {
                icon: 'el-icon-user-solid',
                path: '/participants',
                meta: {level: 10, wide: true, friendly: '与会人', model: 'Participant'},
                component: () => import(/* webpackChunkName: "monitor" */ '../views/admin-participant'),
                name: '与会人',
            },
        ]
    },
    {
        path: '/',
        name: '',
        icon: 'el-icon-help',
        component: () => import(/* webpackChunkName: "home" */ '../views/' + mobile() + 'home.vue'),
        children: [
            {
                path: '/manual-list',
                component: () => import(/* webpackChunkName: "profile" */ '../views/' + mobile() + 'manual-list'),
                name: '使用指南'
            },
        ]
    },

]
