<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'app',
        components: {}
    }
</script>

<style lang="scss">
    body, html, #app {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    body {
        font-family: "arial regular", arial, "Microsoft Yahei", 微软雅黑, PingFangSC-Regular, SimSun, "Songti SC", sans-serif;
        -webkit-font-smoothing: antialiased;
    }
    .fixed-body { overflow-y: hidden}

    .w-100 {
        width: 100%;
    }

    .h-100 {
        height: 100%;
    }


    .line {
        height: 5px;
        border-bottom: 1px solid #d7dae2;
        margin-bottom: 5px;
    }

    .el-dialog__footer {
        border-top:1px solid #eee;
        padding-top: 15px;
    }

    .el-dialog__header {
        border-bottom: 1px solid #eee;
    }


    .el-select__input {

        vertical-align: baseline;

    }
    .el-zoom-in-top-enter-active, .el-zoom-in-top-leave-active {
        transition: opacity 0.2s ease-in,transfrom 0.2s ease-in!important;
    }

    button {
        outline: none!important;
    }

    .el-dialog.mobile-dialog__outer {
        .el-dialog__body {
            flex: 1;
        }
        .el-dialog__body {
            overflow: auto;
            -webkit-overflow-scrolling: touch;
        }
    }

    .el-message-box {
        max-width: 95%;
    }

    .attendee-block {
        display: inline-block;
        border-radius: 5px;
        line-height: 30px;
        background-color: #F5F5F5;
        color: #333;
        padding: 2px 25px 2px 10px;
        position: relative;
        border: 1px solid #ddd;
        transition: all 0.2s ease-in;
        margin-right: 8px;

        .icon-button {
            position: absolute;
            right: 5px;
            top: 2px;
            padding: 0 2px;
            font-size: 15px;
            cursor: pointer;
            transition: all 0.2s ease-in;
            opacity: 0.1;

            &:hover {
                color: #0288d1;
                transform: scale(1.2);
            }
        }

        &:hover {
            border: 1px solid #4dd0e1;
            background-color: #e0f7fa;

            .icon-button {
                opacity: 1;
            }
        }
    }

    .page-container {
        position: relative;
        width: 100%;
        max-width: 990px;
        margin: 0 auto;

    }

    .pull-right {
        float: right!important;
    }

    .pull-left {
        float: left!important;
    }

    .el-link {
        text-decoration: none!important;
    }
    label.el-radio {
        margin-bottom: 0!important;
    }
    label.el-checkbox {
        margin-bottom: 0!important;
    }
    @import "common/scss/import-page";

</style>
