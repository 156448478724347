import Api from "../config/api";
import axios from "axios";

export default {
    namespaced: true,
    state: {
        app:{},
        loaded: false,
    },
    mutations: {
        set(state, payload) {
            state.app = payload;
            state.loaded = true;

            if (state.app.name) {
                document.title = state.app.name;
            }
        },
    },
    getters: {},
    actions:{
      loadPreset(context) {
          const prefix = "storage/preset.json?_t="+Math.random().toString(32);
          const url = Api.url.replace('api/v1', prefix);

          axios.get(url).then(res => {
              if (res.data) {
                  context.commit('set',res.data);
              }
          }).catch(() => {
          });
      }
    }
}
