import Vue from 'vue'
import Router from 'vue-router'
import Home from './home'
import mobile from "../common/mobile-input"

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
      ...Home,
    {
      path: '/sign-in',
      name: 'signin',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // 只有将写成这样才能够识别出来，否则报module没有发现
      component: () => import(/* webpackChunkName: "about" */  '../views/'+mobile()+'sign-in')
      //component:SignIn
    },
    {
      path:'*',
      name:'default',
      redirect:'/',
    }
  ]
})

