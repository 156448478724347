import Vue from 'vue';
import mobile from "../mobile-input"

Vue.directive('mobileDialog', {
    bind(el) {
        if (mobile() === '') return;

        const dialogEl = el.querySelector('.el-dialog');
        if (dialogEl) {
            dialogEl.style = 'height:calc(100% - 10px);margin:5px auto;width:calc(100% - 10px);' +
                'display:flex;flex-direction:column';
            dialogEl.classList.add('mobile-dialog__outer');

        }

        el.classList.add('overflow-hidden');
    }
});
