let logon_template = 'sign-in.vue';
let background_images = null;
let syslogo = null;

//以下内容需要进行修改或者增加
if (process.env.VUE_APP_API_ORG === 'sjtu') {
    logon_template = 'sign-in.vue';
} else if (process.env.VUE_APP_API_ORG === 'shu') {
    background_images =
        ['20191192.jpg',
            '201911084.jpg',
            '20191029.jpg',
            '20191109.jpg'];
    logon_template = 'sign-in.vue';
} else if (process.env.VUE_APP_API_ORG === 'fudan') {
    background_images =
        ['fudan-bg1.jpg','fudan-bg2.jpg','fudan-bg3.jpg'];
    logon_template = 'sign-in.vue';
} else if (process.env.VUE_APP_API_ORG === 'tongji') {
    background_images = ['tsi-ad-banner-0.jpg','tsi-ad-banner-1.jpg','tsi-ad-banner-2.jpg'];
}

const use_id = process.env.VUE_APP_API_ORG !== 'sjtu';
const signin = process.env.VUE_APP_API_ROOT?process.env.VUE_APP_API_ROOT:window.location.origin+'/tms/api/v1';
const signin_url = signin.replace('tms/api/v1','sign-in');
const host_url = signin.replace('/tms/api/v1','');

// 导出内容
export default {
    signin_url:signin_url,
    host_url:host_url,
    sso:process.env.VUE_APP_SSO_LOGIN_REDIRECT,
    sso_out:process.env.VUE_APP_SSO_LOGOUT_REDIRECT,
    org:process.env.VUE_APP_API_ORG?process.env.VUE_APP_API_ORG:process.env.VUE_APP_ORGANIZATION,
    use_id:use_id,
    logon_template:logon_template,
    oauth:process.env.VUE_APP_OAUTH_LOGIN,
    oauth_out:process.env.VUE_APP_OAUTH_LOGOUT,
    wx_redirect:process.env.VUE_APP_API_WX_CORP_REDIRECT,
    h323:process.env.VUE_APP_ORG_H323,
    background_images:background_images,
    syslogo:syslogo,
    oauth_title:process.env.VUE_APP_OAUTH_TITLE,
    oauth_picture:process.env.VUE_APP_OAUTH_PICTURE,
    oauth_helper:process.env.VUE_APP_OAUTH_HELPER,
    disclaimer:process.env.VUE_APP_DISCLAIMER,
    org_name:process.env.VUE_APP_ORG_NAME
}
